<template>
  <div class="px-calendar">
    <!-- v-date-picker has a quirk with min/max. -->
    <!-- if min/max are set and the passed value is a date that does not satisfy min/max it will not render -->
    <!-- we want to be able to show the set value for readonly/disabled cases -->
    <v-date-picker
      ref="calendar"
      v-bind="$attrs"
      class="w-100 lx-date-picker extend-date-picker px-calendar-input"
      :class="{ 'read-only': readOnly }"
      color="blue"
      :data-testid="`date-picker-${value}`"
      :disabled-dates="disabledDates"
      :max-date="disabled && value ? null : max"
      :min-date="disabled && value ? null : min"
      :timezone="timezone"
      :value="value"
      v-on="$listeners"
      @input="updateValue"
    >
      <template v-if="showInput" #default="{ inputValue, togglePopover }">
        <b-input
          :class="{
            invalid: shouldShowValidations && invalid,
          }"
          :disabled="disabled"
          :placeholder="placeholder"
          :value="inputValue"
          @blur="$emit('blur')"
          @click="togglePopover"
          @keypress.prevent
        />
        <span
          v-if="shouldShowValidations && invalid"
          v-b-tooltip.hover.bottomleft="{
            customClass: 'px-tooltip',
            variant: 'light-warning',
            title: invalidMessage,
          }"
          class="invalid-icon-container"
        >
          <px-icon
            class="invalid-icon"
            icon="triangle-exclamation"
            size="sm"
            title="invalid-icon"
          ></px-icon>
        </span>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
export default {
  name: 'PxCalendar',
  props: {
    value: {
      type: Date,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabledDates: {
      type: [Object, Array],
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Date,
      default: null,
    },
    max: {
      type: Date,
      default: null,
    },
    showInput: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: 'select date',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    invalidMessage: {
      type: String,
      default: 'Required',
    },
    shouldShowValidations: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeDate: '',
  }),
  methods: {
    updateValue(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables';
.px-calendar {
  .extend-date-picker {
    border: none;
    background-color: rgba(196, 196, 196, 0.15);
    border-radius: 15px;
    padding: 30px 0;
    height: 290px;

    .vc-day-content:not(.is-disabled) {
      color: $polly-light-blue;
    }
    .vc-highlights + .vc-day-content:not(.is-disabled) {
      color: $white;
    }

    &.read-only .vc-weeks {
      pointer-events: none;
      .vc-day-content:not(.is-disabled) {
        color: $dark-gray;
      }
    }
  }

  input {
    caret-color: transparent;
  }

  .invalid-icon-container {
    align-items: center;
    top: 0;
    height: 100%;
    position: absolute;
    right: 0.875rem;
    display: flex;

    .invalid-icon {
      color: $polly-orange;
    }
  }

  &.invalid {
    border-color: $polly-orange;
    padding-right: 1.5rem;
  }
}
</style>
